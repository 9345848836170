body {
  /* background-color: #8bc6ec; */
  background-image: url("./media/BackgroundGray.jpg");
  background-size: 100%;
  /* background-size: cover; */
  /* background-image: linear-gradient(135deg, #8bc6ec 0%, #9599e2 100%); */

  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
