.containerLargeScreen {
  width: 50%;
  display: flex;
  flex-direction: column;
  height: fit-content;
  margin-top: 16px;
}

.containerSmallScreen {
  width: 90%;
  display: flex;
  flex-direction: column;
  height: fit-content;
  margin-top: 16px;
}

.page {
  width: 100%;
  margin-bottom: 16px;
}
